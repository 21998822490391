import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import "./../css/GalleryTemplate.css"
import pictureSvg from "./../images/images-regular.svg";
import Loadable from "@loadable/component"
import 'react-modal-video/scss/modal-video.scss';


const ModalVideo = Loadable(() => import('react-modal-video'))


function GalleryTemplate(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");


    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState([]);


    console.log(props)
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function openGallery(index) {
        let tmpGallery = [];
        data.allWpPage.nodes[0].mediaAcf.galleryImages.gallery[index].galleryImages.map((gi, i) => {
            tmpGallery.push(gi.image.sourceUrl)
        })

        setImages(tmpGallery);
        setPhotoIndex(0)
        setIsOpen(true)
        console.log(data.allWpPage.nodes[0].mediaAcf.galleryImages.gallery[index])
    }

    function openVideo(videoUrl) {
        console.log(videoUrl);
        var final = videoUrl.substr(videoUrl.lastIndexOf('/') + 1);
        console.log(final)
        setVideoUrl(final)
        setIsVideoOpen(true)
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>


                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <section>
                        <div className="container-cs py-16 mx-auto">
                            <div>
                                <div className="h-full">
                                    {/* <GatsbyImage
                                        image={data.allWpPage.nodes[0].mediaAcf.galleryImages.image.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.allWpPage.nodes[0].mediaAcf.galleryImages.image.altText}
                                        className=" m-auto"
                                    /> */}

                                    <img  className="object-fill m-auto" src={data.allWpPage.nodes[0].mediaAcf.galleryImages.image.sourceUrl} alt={data.allWpPage.nodes[0].mediaAcf.galleryImages.image.altText} />

                                </div>
                                <div className="flex flex-wrap pt-8">
                                    {data.allWpPage.nodes[0].mediaAcf.galleryImages.gallery && data.allWpPage.nodes[0].mediaAcf.galleryImages.gallery.map((gal, i) => (
                                        <div key={`photo-gallery-${i}`} className="gl-i-cont w-full md:w-1/4 pr-0 md:pr-2 pt-4 md:pt-0">
                                            <div className="relative overflow-hidden flex h-full">
                                                {/* <GatsbyImage
                                                    image={gal.image.localFile.childImageSharp.gatsbyImageData}
                                                    alt={gal.image.altText}
                                                    className=" m-auto"
                                                /> */}
                                                <img className="m-auto " src={gal.image.sourceUrl} alt={gal.image.altText} />

                                                <div className="overlay-shade flex justify-center items-center"></div>
                                                <p>{gal.title}</p>
                                                <div className="album-icon">
                                                    <div onClick={() => openGallery(i)}>
                                                        <div>
                                                            <img src={pictureSvg} alt="album-icon" style={{ width: "25px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="py-8">
                                <div>
                                    {/* <GatsbyImage
                                        image={data.allWpPage.nodes[0].mediaAcf.galleryVideos.image.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.allWpPage.nodes[0].mediaAcf.galleryVideos.image.altText}
                                        className=" m-auto"
                                    /> */}
                                    <img className=" m-auto" src={data.allWpPage.nodes[0].mediaAcf.galleryVideos.image.sourceUrl} alt={data.allWpPage.nodes[0].mediaAcf.galleryVideos.image.altText} />

                                </div>
                                <div className="pt-8">
                                    <div className="relative">
                                        {/* <GatsbyImage
                                            image={data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.image.localFile.childImageSharp.gatsbyImageData}
                                            alt={data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.image.altText}
                                            className=" m-auto"
                                        /> */}
                                        <img className=" m-auto" src={data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.image.sourceUrl} alt={data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.image.altText} />

                                        <div className="video-container" onClick={() => openVideo(data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.url)}>
                                            <div className="video-button" />
                                        </div>
                                    </div>
                                    <h5 className="text-center text-black" style={{ fontSize: "1.125rem", fontWeight: "700" }}>{data.allWpPage.nodes[0].mediaAcf.galleryVideos.featuredVideo.title}</h5>
                                </div>

                                <div className="pt-8">
                                    <div className="flex flex-wrap">
                                        {data.allWpPage.nodes[0].mediaAcf.galleryVideos.gallery && data.allWpPage.nodes[0].mediaAcf.galleryVideos.gallery.map((vd, i) => (
                                            <div className={`w-full pt-8 px-2 ${i < 3 ? "md:w-1/3" : "md:w/1-4 lg:w-1/5"}`} key={`gallery-video-${i}`}>
                                                <div className="relative">
                                                    {/* <GatsbyImage
                                                        image={vd.videoGroup.image.localFile.childImageSharp.gatsbyImageData}
                                                        alt={vd.videoGroup.image.altText}
                                                        className=" m-auto"
                                                    /> */}

                                                    <img className=" m-auto" src={vd.videoGroup.image.sourceUrl} alt={vd.videoGroup.image.altText} />

                                                    <div className="video-container" onClick={() => openVideo(vd.videoGroup.url)}>
                                                        <div className="video-button" />
                                                    </div>
                                                </div>
                                                <h5 className="text-center text-black pt-2" style={{ fontSize: "1.125rem", fontWeight: "700" }}>{vd.videoGroup.title}</h5>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="pt-8">
                                    {/* <GatsbyImage
                                        image={data.allWpPage.nodes[0].mediaAcf.tvVideos.image.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.allWpPage.nodes[0].mediaAcf.tvVideos.image.altText}
                                        className=" m-auto"
                                    /> */}
                                    <img className=" m-auto" src={data.allWpPage.nodes[0].mediaAcf.tvVideos.image.sourceUrl} alt={data.allWpPage.nodes[0].mediaAcf.tvVideos.image.altText} />

                                </div>

                                <div className="pt-8">
                                    <div className="flex flex-wrap">
                                        {data.allWpPage.nodes[0].mediaAcf.tvVideos.galleryVideos && data.allWpPage.nodes[0].mediaAcf.tvVideos.galleryVideos.map((vd, i) => (
                                            <div className={`w-full pt-8 px-2 md:w/1-4 lg:w-1/5`} key={`tv-video-${i}`}>
                                                <div className="relative">
                                                    {/* <GatsbyImage
                                                        image={vd.image.localFile.childImageSharp.gatsbyImageData}
                                                        alt={vd.image.altText}
                                                        className=" m-auto"
                                                    /> */}
                                                    <img className=" m-auto" src={vd.image.sourceUrl} alt={vd.image.altText} />

                                                    <div className="video-container" onClick={() => openVideo(vd.url)}>
                                                        <div className="video-button" />
                                                    </div>
                                                </div>
                                                <h5 className="text-center text-black pt-2" style={{ fontSize: "1.125rem", fontWeight: "700" }}>{vd.title}</h5>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>


                    {(isOpen && images.length > 0) && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                        />
                    )}

                    <ModalVideo channel='youtube' autoplay isOpen={isVideoOpen} videoId={videoUrl} onClose={() => setIsVideoOpen(false)} ratio={'16:9'} />



                </div>
            </Layout>
        </div>
    );
}

export default GalleryTemplate



export const pageQuery = graphql`query GET_GALLERY_MEDIA($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
       mediaAcf {
        galleryImages{
          image{
            sourceUrl
            altText
            
          }
          gallery{
            title
            image{
                altText
                sourceUrl
                

            }
            galleryImages{
              image{
                altText
                sourceUrl
                
              }
            }
          }
        }
        galleryVideos{
          image{
            sourceUrl
            altText
            

          }
          featuredVideo{
            title
            url
            image{
                altText
                sourceUrl
                
            }
          }
          gallery{
            videoGroup{
              title
              url
              image{
                altText
                sourceUrl
                
              }
            }
          } 
        }
        tvVideos{
          image{
            sourceUrl
            altText
            

          }
          galleryVideos{
            title
            url
            image{
                altText
                sourceUrl
                
            }
          }
        }
      }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
